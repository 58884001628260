export default {
  GET(state, data) {
    state.substitutes = data
  },
  LIST(state, data) {
    state.components = data
  },
  SET_SUBSTITUTES_FORM(state, data) {
    state.substitutesForm = data
  },
}
