import store from '@/store'
import axios from '@/libs/axios'
import crudActions from '@/libs/storeConstActions'

const endpoint = () => `products/${store.state.catalogs?.catalog?.id}/substitutes`
const updateSub = (ctx, data) => axios.put(endpoint(), data)
const Actions = crudActions(endpoint)
const getSubstitutes = ({ commit }, queryParams) => new Promise((resolve, reject) => {
  axios.get(`/${endpoint()}`, { params: queryParams })
    .then(response => {
      commit('GET', response.data.data)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})
export default {
  getSubstitutes,
  ...Actions,
  updateSub,
}
